import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"

const AboutPage = props => {
  const { location, title } = props
  return (
    <Layout location={location} title={title}>
      <SEO title="關於我們" />

      <header
        style={{
          marginTop: "2rem",
        }}
      >
        <h1
          style={{
            color: "var(--BLUE_TEXT)",
          }}
        >
          關於我們
        </h1>
      </header>
      <article>
        <p>
          大概只有緣分，才能讓本來在地圖兩端、文化背景和國籍完全不同的兩個人相遇，並在初見面後的10天就決定共度一生。
        </p>

        <p>對，這就是我們的故事，也是這個blog誕生的靈感。</p>
        <p>Italically就是義式生活的意思。</p>
        <p>
          因此，我們希望成為義式文化和台灣文化的交流站，尤其是讓我們的讀者可以探索下列主題：
        </p>
        <ul>
          <li>義大利文無痛學習</li>
          <li>義大利文化</li>
          <li>義大利景點</li>
          <li>義大利美食</li>
          <li>台義文化趣事</li>
          <li>異國家庭生活經</li>
          <li>義大利相關疑問</li>
        </ul>
        <p>
          所以，如果你對義大利文化或是義大利文感興趣，或是你正計畫去義大利旅行甚至已經在義大利了，這個部落格正是你所需要的!
        </p>
        <h2
          style={{
            color: "var(--BLUE_TEXT)",
          }}
        >
          關於作者
        </h2>
        <h3
          style={{
            color: "var(--ORANGE)",
          }}
        >
          Nicco (老雷)
        </h3>
        <p>
          我是來自義大利的Nicco，特別喜歡教中文的學生學習義大利文和分享義式文化，同時精進自己的中文。
        </p>
        <p>
          這一切起源於從小對於亞洲文化的興趣，透過對忍者龜、七龍珠和李小龍電影的喜愛，發現中國武術和空手道對自己有強烈的吸引力。
        </p>
        <p>
          高中之後，因為想要探索更多中華文化，進而正式開始學習中文。在一些台灣朋友的建議下，終於得到幾次在台灣學習的機會，分別就讀於師範大學和文化大學。過程中我深深愛上台灣這個國家，當學習期滿必須回到義大利時，也對自己立下總有一天要再回台灣的誓言。
        </p>
        <p>
          回到義大利，開始在義漢橋語言學校教授義大利文，這是一間主要針對母語中文的人士開設的語言學校。教母語中文的學生和西方學生的經驗完全不同，於我而言也是很棒的經驗!
        </p>
        <p>
          隨著日子過去，對於台灣的思念有增無減，對自己的承諾也是一直在耳邊。機緣巧合下，接觸了一個語言交換的app，本意是希望認識一些在義大利的台灣人，可以繼續分享交流台灣的大小事，畢竟內向的我，其實很不容易認識新朋友。
        </p>
        <p>
          緣分總出現在意想不到的情況裡，誰會知道我竟然從這裡認識了完全改變我生活、並且實現我夢想的人-我的老婆。
        </p>
        <p>
          總而言之，我們初次相見是在義大利，很快的我們結了婚、定居台灣，並且有了一個孩子。
        </p>
        <p>
          當我成為台灣女婿，回到台灣並且在文大繼續教義大利文，我遇到一群很棒很酷的同事和學生，並且非常開心能成為朋友。
        </p>
        <p>
          在台灣生活幾年之後，現在我們決定一家人一起去義大利生活，開啟新的篇章。
        </p>
        <p>如果你對於故事未來的發展感興趣，請繼續看下去……</p>
        <h3
          style={{
            color: "var(--ORANGE)",
          }}
        >
          Lala
        </h3>
        <p>
          我是台北俗Lala，特別喜歡發現生活中那些值得細細品味的事。現在為全職媽媽，每天和我們可愛的兒子Donnie一起成長。
        </p>
        <p>
          從小就是個教室坐不住、家裡待不住的好動孩子。國小開始接觸田徑運動，一路相伴相依到高中畢業，大學把好動領域擴大，從田徑拉到體育系，又因為話多所以接觸棒球播報員的工作。也許是愛聊天也敢開口的個性，25歲時砸下自己所有積蓄，隻身到歐洲學習旅行3個月。
        </p>
        <p>
          怎麼也沒想到，這一趟學習之旅讓我更了解自己之外，還遇到了我的“zing”那個傳說中，你見到他就知道，你會跟他共度餘生的人-我的老公。總之，廣義來說我們是閃婚沒錯，但是為了克服跨海文件等重重關卡，相見到結婚大概也花了7個月，從2016年10月開始把遠距離變成近距離。
        </p>
        <p>
          而我在經歷了2017台北世大運委員會和產後護理之家客服人員兩個性質極度不同的工作之後，現在也在育兒之路上戰戰兢兢摸索中。
        </p>
        <p>2020，我們決定一家人一起去義大利生活，開啟新的篇章。</p>
        <p>如果你對於故事未來的發展感興趣，請繼續看下去……</p>
        <p>
          同時，
          <Link
            className="highlight--orange"
            to="/blog/義大利文輕鬆學_1_旅遊相關詞彙"
          >
            如果你希望開始學習義大利文，並且知道更多我們的故事
          </Link>
          ，按下去。
        </p>
      </article>
    </Layout>
  )
}

export default AboutPage

export const aboutQuery = graphql`
  query aboutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
